@import 'two-app-ui/build/esm/src/scss/theme/_variables';

.dashboard-component {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  flex: 1 1 100%;
  max-width: 100%;
  width: 100%;

  .p-card,
  .p-card-body,
  .p-card-content,
  .p-carousel {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .p-card-content {
    padding: 0px;
  }

  .p-card {
    box-shadow: none;
    border-radius: 6px;
    height: 100%;
  }
}

.quick-action-btn {
  background-color: #3B82F6;
  color: white;
  border-radius: 0.5rem !important;
}

.quick-action-btn:hover {
  background-color: #3B82F6 !important;
}

.position-r {
  float: right;
}

.carousel-news .news-item .news-item-content {
  border: 0px solid var(--surface-d);
  border-radius: 6px;
  margin: 0.3rem;
  text-align: center;
  padding: 2rem 0;
}

.orders-overview {
  height: 100%;

  .p-card-header {
    display: flex;
    color: $shade500;
    font-weight: bold;
    justify-content: center;
    margin-top: 10px;
  }

  .orders-stage {
    height: 100%;

    .p-panel-header {
      justify-content: center;
      padding: 10px;
      border-radius: 0px;
      color: black;
    }
    .p-panel-content {
      padding: 0px;
    }

    .order-subtitle {
      font-weight: normal !important;
    }
  }
}

.carousel-demo .product-item .product-image {
  width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-carousel-content {
  background-color: #e9ebed;
  border-radius: 6px;
  border: 1px solid #DEE2E6;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #3B82F6 ;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.inline {
  display: inline-block;
  grid-gap: 20px;
}
.pl-6 {
  padding-left: 9px;
}

