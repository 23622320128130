//Order and job stage badge style and colors
.stage-badge {
  border-radius: 2px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.3px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  &.stage-draft,
  &.stage-estimate,
  &.stage-measure,
  &.stage-repair-estimate,
  &.stage-drawings-awaiting-approval,
  &.job-stage-measure-planned,
  &.job-stage-measure-review,
  &.job-stage-measure-approval-pending,
  &.job-stage-install-planned,
  &.job-stage-install-review {
    background-color: #5486ea;
    color: #ffffff;
  }
  &.stage-cancelled,
  &.job-stage-canceled {
    background-color: #ea5455;
    color: #ffffff;
  }
  &.stage-delivered,
  &.job-stage-completed,
  &.job-stage-completed-with-repair {
    background-color: #29c76f;
    color: #ffffff;
  }
  &.stage-scheduled-for-production,
  &.stage-production-complete,
  &.stage-credit-check,
  &.stage-drawing-notes-awaiting-approval,
  &.stage-drawings-approved,
  &.stage-new,
  &.stage-drawings-required,
  &.stage-re-draw,
  &.stage-in-production,
  &.stage-in-shipping,
  &.job-stage-measure-booked,
  &.job-stage-install-booked,
  &.job-stage-in-production,
  &.job-stage-in-shipping,
  &.job-stage-grey {
    background-color: #bcbec0;
    color: #000000;
  }
  &.job-stage-new {
    background-color: #a600a6;
    color: #ffffff;
  }
  &.job-stage-assigned,
  &.job-stage-measure-started,
  &.job-stage-measure-finished,
  &.job-stage-install-started,
  &.job-stage-install-finished {
    background-color: #ffa500ff;
    color: #000000;
  }
}

.stage-badge-filter {
  padding: 0.1px 0.1px;
  text-transform: none !important;
  font-weight: inherit !important;
  font-size: inherit !important;
  letter-spacing: inherit !important;
  height: 100%;
}

.stage-filter {
  .p-multiselect-label {
    height: 100%;
  }
}

.p-datatable-row-expansion {
  td:first-child {
    padding: 0px 10px 0px 50px !important;
    z-index: 0;
  }
}

.p-datatable .p-datatable-row-expansion {
  //this is for descendants table's tables
  th,
  .p-paginator {
    //header and footer color of table's child table
    background-color: #dbe5ed !important;
  }
  .p-datatable-row-expansion {
    th,
    .p-paginator {
      //header and footer color of table's grandchild table
      background-color: #d9e7df !important;
    }
  }
}
