@import "two-app-ui/build/esm/src/scss/theme/_variables";

#original_page_container{
  height: 100%;
}

#original_request_page {
  .p-scrollpanel {
    height: calc(100% - 1rem - 2px - 1.4rem - 1rem);
    min-width: 100%;
    .p-scrollpanel-bar-y {
      width: 0;
    }
  }
  .job-link{
    color: #3b82f6;
  }
}
