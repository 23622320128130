#orders_overview {
  .overview-accordion {
    height: 100%;
    display: flex;
    flex-direction: column;
    .p-accordion-tab{

      &.p-accordion-tab-active{
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        .p-toggleable-content{
          height: 100%;
          flex: 1 1 auto;
          .p-accordion-content{
            height: 100%;
          }
        }
      }
    }
  }
}

